import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Nav from './components/Nav/Nav';
import HomeNav from './components/HomeNav/HomeNav';
import Footer from './components/Footer/Footer';
import wsp from './assets/whatsapplogo.png';
import call from './assets/calllogo.png';

const Layout = () => {
  const [isHome, setIsHome] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setIsHome(true);
    }
    else {
      setIsHome(false)
    }
  }, [location]);

  return (
    <div className="App">
      {isHome ? (
        <HomeNav />
      ) : (
        <Nav />
      )}

      <Outlet />

      <div className='whatsappBtnDiv'>
        <a href="tel:+918810523732">
          <img src={call} />
        </a>
        <a href="https://wa.me/+918810523732" target='_blank'>
          <img src={wsp} />
        </a>
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
