import React, {useEffect} from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'

const ReadMore2 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='ServicePage'>

      <div className='proj_LargeHead'>Plumbing Services</div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
        Is your plumbing system acting up? From leaky faucets to clogged drains, 
        our expert plumbers are here to provide reliable and efficient solutions. 
        With years of experience and a commitment to quality workmanship, 
        we offer a wide range of plumbing services to meet your needs. 
        Whether it's a simple repair or a major installation, 
        you can count on us to deliver exceptional service.
        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
        Our team of skilled plumbers is equipped with the latest tools and techniques 
        to diagnose and resolve plumbing issues quickly and effectively. 
        We specialize in everything from pipe repairs and fixture replacements 
        to drain cleaning and sewer line maintenance. No job is too big or too 
        small for our experienced professionals. Contact us today for a free 
        estimate and let us restore your plumbing system to its optimal condition.
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>Pipe repairs</li>
          <li className='proj_service'>Fixture repairs and replacements</li>
          <li className='proj_service'>Drain cleaning</li>
          <li className='proj_service'>Sewer line repair and replacement</li>
          <li className='proj_service'>Water heater repair and replacement</li>
          <li className='proj_service'>Gas line installation and repair</li>
          <li className='proj_service'>Plumbing inspections</li>
          <li className='proj_service'>Emergency plumbing services</li>

        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}


export default ReadMore2
