import React, { useEffect } from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore7 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='ServicePage'>

      <div className='proj_LargeHead'>Security Services</div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
          Protect your property and loved ones with our highly trained and reliable security guards. Our team of dedicated professionals is committed to providing exceptional security services that ensure your safety and peace of mind. We offer a wide range of services, including residential security, commercial security, event security, and more.


        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
          Our guards are equipped with the latest security equipment and undergo rigorous training to handle various situations.
          They are committed to maintaining a professional and courteous demeanor while effectively deterring crime and protecting your assets. Trust us to provide the highest level of security for your home or business.
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>









          <li className='proj_service'>Residential security</li>
          <li className='proj_service'>Commercial security</li>
          <li className='proj_service'>Event security</li>
          <li className='proj_service'>Patrolling</li>
          <li className='proj_service'>Access control</li>
          <li className='proj_service'>Alarm monitoring</li>
          <li className='proj_service'>CCTV surveillance</li>
          <li className='proj_service'>Emergency response</li>


        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore7