import React, {useEffect} from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore7 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    return (
        <div className='ServicePage'>
    
          <div className='proj_LargeHead'>Cook/ Chef</div>
    
          <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
        Elevate your dining experience with our exceptional cooking services. Our skilled chefs are passionate about creating delicious and innovative dishes that cater to your taste preferences and dietary needs. Whether you're hosting a special occasion or simply looking for a gourmet meal at home, our chefs will deliver exceptional culinary experiences.      
      </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
        From classic comfort food to international cuisine, our chefs have the expertise to prepare a wide variety of dishes. We use only the freshest ingredients and the latest cooking techniques to ensure that every meal is a masterpiece. Whether you have a specific dietary restriction or simply prefer a certain style of cooking, our chefs will customize their menu to your preferences.      
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
        <li className='proj_service'>Private chef services</li>
          <li className='proj_service'>Meal prep services</li>
          <li className='proj_service'>Catering services</li>
          <li className='proj_service'>Cooking classes</li>
          <li className='proj_service'>Food delivery services</li>
          <li className='proj_service'>Custom meal plans</li>
          <li className='proj_service'>Grocery shopping and meal planning</li>
          <li className='proj_service'>Recipe development</li>
        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore7