import React, { useState } from 'react'
import logo from '../../assets/logo white.png';
import './Footer.css'
import fb from '../../assets/fblogo.png'
import ig from '../../assets/iglogo.png'
import TTM from '../../assets/TTM.png'
import EastIcon from '@mui/icons-material/East';
import { Link } from 'react-router-dom'
import { Height } from '@mui/icons-material'

const Footer = () => {

    const [callus, setcallus] = useState(false)

    return (
        <div className='Footer'>
            <div className='footerContent'>

                <div>
                    <div className='footerHeading'>
                        <img src={logo} className='footerLogo' />
                    </div>
                    <div className='footerAddress'>
                        <div className='footerBold '>INDIA</div>
                        <div>Metro Pillar Number 55, 122002, Sikanderpur Market, Sikanderpur, </div>
                        <div>DLF Phase 1, Sector 26, Gurugram, Haryana 122022</div>
                        
                    </div>
                </div>

                <div>
                    <div className='footerHeading footerBold'>QUICK LINKS</div>
                        <div className='footerList'>
                            <div> <a href="about">About Us</a></div>

                                <div>
                                    <Link to ='/sharerequirements' >Share Requirements</Link>
                                </div>
                        </div>
                    </div>


                <div>
                    <div className='footerHeading footerBold'>
                        LET'S TALK
                    </div>
                    <div className='footerList'>
                        <div>
                            <a href='mailto:urbanhappyhome2024@gmail.com' target='_blank'>
                                Mail Us
                            </a>
                        </div>

                        <div className={callus ? 'footerOrange footerbtnwidth' : 'footerOrange'}
                            onClick={() => setcallus(!callus)}>
                            {callus ?
                                <>+918810523732 <EastIcon /></>
                                :
                                <>
                                    <b>Contact Us</b> <EastIcon />
                                </>}
                        </div>
                        <div>

                        </div>
                        <div>

                        </div>
                    </div>
                </div>

            </div>


            {/* <div className='footerTerms'>
                <div>Terms of Service</div>
                <div>Terms & Conditions</div>
                <div>Privacy Policy</div>
            </div> */}

            <div className='footerCopyright'>
                ©2024 Urban Happy Homes, All Rights Reserved. <br />
                (<a href="https://www.techtonicmedia.in/" target='_blank'>Developed by TechTonic Media</a>)
            </div>
            <div>
            <a href="https://www.techtonicmedia.in/" target='_blank'>
                <div><img src={TTM}  className='footerTechTonicMedia'/></div>
            </a>
            </div>
        </div>
    )
}

export default Footer
