import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './ReadMore.css'

const ReadMore1 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='ServicePage'>

      <div className='proj_LargeHead'>Electrical Repairing Services</div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
          Are you facing electrical issues in your home or business? From faulty outlets to flickering lights,
          our team of experienced electricians is here to provide reliable and efficient solutions.
          We specialize in a wide range of electrical services, including installations, repairs, and maintenance.
          With a commitment to safety and customer satisfaction, we strive to deliver top-notch workmanship and timely service.
        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
          Whether you need a simple light fixture replaced or a complete electrical system overhaul,
          we have the expertise to handle it all. Our technicians are equipped with state-of-the-art
          tools and techniques to diagnose and resolve electrical problems quickly and effectively.
          We also offer emergency electrical services for those urgent situations that can't wait.
          Trust us to keep your electrical systems running smoothly and safely.
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>Wiring repairs</li>
          <li className='proj_service'>Outlet and switch repairs</li>
          <li className='proj_service'>Circuit breaker troubleshooting</li>
          <li className='proj_service'>Lighting repairs</li>
          <li className='proj_service'>Appliance repairs</li>
          <li className='proj_service'>Surge protector installation</li>
          <li className='proj_service'>Emergency electrical services</li>
          <li className='proj_service'>Electrical inspections</li>

        </ul>
        {/* <a href="https://forms.gle/k4h965w1DFTFGrCcA"> */}
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore1
