import React, { useEffect, useState } from 'react'
import c0 from '../../../assets/c-0.png'
import c1 from '../../../assets/c-1.png'
import c2 from '../../../assets/c-2.png'
import c3 from '../../../assets/c-3.png'
import c4 from '../../../assets/c-4.png'
import './Challenges.css'

const Challenges = () => {


    return (
        <>
            <div className='Challenges'>

                <div className='challengesHead'>Why Urban Happy Homes?</div>

                {/* <div className='text'>tform simplifies agency connections, <br />allowing you to focus on core business while we manage vendor intricacies.</div> */}

                <div className='challengesContainer'>

                    <div className='CC_box2'>
                        <div className='flip-box-inner2'>
                            <div className='flip-box-front2'>
                                <img src={c1} className='CC_icon' />
                                <div className='subHead'>Ensuring Quality and <br />Reliability</div>
                            </div>

                            <div className='flip-box-back2'>
                                <div className='CC_text'> Urban Happy Homes meticulously evaluates and validates all vendor agencies in our network to ensure they meet our stringent standards. With our quality assurance processes in place, you can trust that you are working with reliable partners who deliver top-notch vendor solutions.</div>
                            </div>
                        </div>

                    </div>

                    <div className='CC_box2'>

                        <div className='flip-box-inner2'>
                            <div className='flip-box-front2'>
                                <img src={c2} className='CC_icon' />
                                <div className='subHead'>Managing Multiple Service <br />Providers</div>
                            </div>

                            <div className='flip-box-back2'>

                                <div className='CC_text'> Urban Happy Homes provides a one-stop solution for all your vendor needs.
                                    Say goodbye to the hassle of dealing with multiple service providers. We streamline the process,
                                    allowing you to access a diverse range of vendor options through a single platform.</div>
                            </div>
                        </div>

                    </div>

                    <div className='CC_box2'>




                        <div className='flip-box-inner2'>
                            <div className='flip-box-front2'>
                                <img src={c3} className='CC_icon' />
                                <div className='subHead'>Navigating Legal and <br />Compliance Issues</div>
                            </div>

                            <div className='flip-box-back2'>
                                <div className='CC_text'> Urban Happy Homes helps navigate the complexities of labor laws,
                                    regulations, and industry standards. Our team ensures compliance across different regions,
                                    providing peace of mind and mitigating legal risks for your business.</div>
                            </div>
                        </div>
                    </div>

                    <div className='CC_box2'>

                        {/* <div className='subHead'>Finding the Right Talent</div> */}
                        <div className='flip-box-front2'>
                            <img src={c4} className='CC_icon' />
                            <div className='subHead'>Finding the Right Talent</div>
                        </div>
                        {/* <div className='CC_text'> Urban Happy Homes offers tailored staffing solutions, matching businesses with the right talent for their specific requirements. Our extensive network
                 of vetted staffing agencies ensures access to high-quality candidates who meet your company's unique needs.</div> */}
                        <div className='flip-box-back2'>
                            <div className='CC_text'> Urban Happy Homes offers tailored vedor solutions, matching businesses with the right talent for their specific requirements. Our extensive network
                                of vetted vendor agencies ensures access to high-quality candidates who meet your company's unique needs.</div>
                        </div>
                    </div>
                </div>

            </div>



        </>
    )
}

export default Challenges