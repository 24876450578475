import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import Loader from '../../Loader/Loader';
import './FeaturedCompanies.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Electrical from '../../../assets/Electrical.png';
import Plumbing from '../../../assets/Plumbing.png';
import Carpenter from '../../../assets/Carpenter.png';
import AC from '../../../assets/AC.png';
import Interior from '../../../assets/Interior.png';
import Painting from '../../../assets/Painting.png';
import AMC from '../../../assets/AMC.png';
import Chef from '../../../assets/chef.png';
import Maid from '../../../assets/maid.png';
import Baby from '../../../assets/baby.png';
import Driver from '../../../assets/driver.png';
import Guard from '../../../assets/guard.png';
import Cleaner from '../../../assets/cleaner.png';
import Servant from '../../../assets/servant.png';

import { Pagination, Navigation, Mousewheel, Autoplay } from 'swiper/modules';

const FeaturedCompanies = () => {

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);


  return (
    <div className='FeaturedCompanies'>

      <div className='FC_container'>
        <Swiper
          slidesPerView={3}
          spaceBetween={24}
          loop={true}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          speed={2500}
          // pagination={true}
          breakpoints={{
            250: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            800: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1224: {
              slidesPerView: 3,
              spaceBetween: 24,
            }
          }}
          modules={[Pagination, Navigation, Mousewheel, Autoplay]}
          className="mySwiper"
        >
                    <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Chef} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                Cook/ Chef
                </div>
                <Link to="/service8">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Maid} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                  Maid
                </div>
                <Link to="/service9">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Baby} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                  Baby Care
                </div>
                <Link to="/service10">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Driver} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                  Experienced Driver
                </div>
                <Link to="/service11">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Guard} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                Security Guards
                </div>
                <Link to="/service12">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Cleaner} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                  House Cleaner
                </div>
                <Link to="/service13">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Servant} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                  Servant
                </div>
                <Link to="/service14">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Electrical} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                Electrical Repairing Services
                </div>
                <Link to="/service1">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Plumbing} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                Plumbing Services
                </div>
                <Link to="/service2">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Carpenter} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                Carpenter Services
                </div>
                <Link to="/service3">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={AC} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                AC Installation & Repair Services
                </div>
                <Link to="/service4">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Interior} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                Interior Work Services
                </div>
                <Link to="/service5">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={Painting} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                House Painting Services
                </div>
                <Link to="/service6">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='serviceBox'>
              <div className='serviceImgDiv'>
                <img src={AMC} loading='lazy' />
              </div>
              <div className='serviceContent'>
                <div className='serviceName'>
                AMC Services For Appliances Repair
                </div>
                <Link to="/service7">
                  <button className='serviceBtn'>
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>

        </Swiper>


      </div>
    </div>
  );
}

export default FeaturedCompanies;
