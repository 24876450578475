import React from 'react'
import steps from '../../../assets/4steps.png'
import Svg from '../Svg'
import './Benefits.css'


const Benefits = () => {
  return (
    <div className='Benefits'>

        <div className='stepsContainer'>
          <img src={steps}/>
        </div>
    </div>
  )
}

export default Benefits