import React, {useEffect} from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore6 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    return (
        <div className='ServicePage'>
    
          <div className='proj_LargeHead'>House Painting Services</div>
    
          <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
        Transform the look of your home with our professional house painting services. Our skilled painters use only the highest quality paints and techniques to deliver a flawless finish. Whether you're looking for a fresh coat of paint for your interior or exterior, we have the expertise to create a beautiful and durable result.        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
        From color consultations to surface preparation and meticulous application, our team of painters takes pride in their work. We offer a wide range of services, including interior and exterior painting, ceiling painting, trim painting, and more. Contact us today for a free estimate and let us enhance the beauty and value of your home.        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>Interior painting</li>
          <li className='proj_service'>Exterior painting</li>
          <li className='proj_service'>Ceiling painting</li>
          <li className='proj_service'>Trim painting</li>
          <li className='proj_service'>Cabinet painting</li>
          <li className='proj_service'>Deck painting</li>
          <li className='proj_service'>Fence painting</li>
          <li className='proj_service'>Power washing</li>
          <li className='proj_service'>Color consultation</li>
          <li className='proj_service'>Surface preparation</li>
        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore6