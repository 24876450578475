import React, {useEffect} from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore7 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    return (
        <div className='ServicePage'>
    
          <div className='proj_LargeHead'>AMC Services For Appliances Repair</div>
    
          <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
        Protect your valuable appliances with our comprehensive AMC (Annual Maintenance Contract) services. Our expert technicians offer regular maintenance and repairs to ensure your appliances operate at peak efficiency and minimize unexpected breakdowns. With an AMC, you can enjoy peace of mind knowing that your appliances are in good hands.      
      </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
        Our AMC plans cover a wide range of appliances, including refrigerators, washing machines, air conditioners, geysers, and more. Our technicians will conduct routine inspections, clean and maintain components, and address any minor issues before they escalate into major problems. By investing in an AMC, you can save money on costly repairs and extend the lifespan of your appliances.      
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
        <li className='proj_service'>Routine inspections</li>
          <li className='proj_service'>Cleaning and maintenance</li>
          <li className='proj_service'>Minor repairs</li>
          <li className='proj_service'>Preventive maintenance</li>
          <li className='proj_service'>Emergency repairs</li>
          <li className='proj_service'>Parts replacement</li>
          <li className='proj_service'>Labor costs</li>

        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore7