import React, { useEffect } from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore7 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='ServicePage'>

      <div className='proj_LargeHead'>Baby Care</div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
          Let our experienced and nurturing caregivers provide the best possible care for your precious baby. Our dedicated team is committed to creating a safe, loving, and stimulating environment where your child can thrive. We offer a variety of services, including full-time childcare, part-time childcare, and hourly care, to meet your family's unique needs.
        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
          From feeding and diapering to playtime and learning activities, our caregivers are trained to provide the highest quality care. We prioritize your child's physical, emotional, and cognitive development, and we work closely with parents to ensure that their needs are met. Trust us to provide your child with the love, attention, and support they deserve.
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>Full-time childcare</li>
          <li className='proj_service'>Part-time childcare</li>
          <li className='proj_service'>Hourly care</li>
          <li className='proj_service'>Overnight care</li>
          <li className='proj_service'>Infant care</li>
          <li className='proj_service'>Toddler care</li>
          <li className='proj_service'>Child development activities</li>
          <li className='proj_service'>Meal preparation</li>
          <li className='proj_service'>Diapering and hygiene</li>
          <li className='proj_service'>Transportation</li>
          <li className='proj_service'>Emergency care</li>

        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore7