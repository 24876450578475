import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import HomePage from './components/Home/HomePage';
import AboutPage from './components/About/AboutPage';
import LoginPage from './components/Login/LoginPage';
import { useState, useRef } from 'react';
import axios from 'axios'
import ShareReq from './components/ShareReq/ShareReq';
import ReadMore1 from './components/ReadMore/ReadMore1';
import ReadMore2 from './components/ReadMore/ReadMore2';
import ReadMore3 from './components/ReadMore/ReadMore3';
import ReadMore4 from './components/ReadMore/ReadMore4';
import ReadMore5 from './components/ReadMore/ReadMore5';
import ReadMore6 from './components/ReadMore/ReadMore6';
import ReadMore7 from './components/ReadMore/ReadMore7';
import ReadMore8 from './components/ReadMore/ReadMore8';
import ReadMore9 from './components/ReadMore/ReadMore9';
import ReadMore10 from './components/ReadMore/ReadMore10';
import ReadMore11 from './components/ReadMore/ReadMore11';
import ReadMore12 from './components/ReadMore/ReadMore12';
import ReadMore13 from './components/ReadMore/ReadMore13';
import ReadMore14 from './components/ReadMore/ReadMore14';


function App() {

  axios.defaults.withCredentials = true;
  

  return (


    <Routes>
        
      <Route  path="/" element={<Layout />}>
            
              <Route 
              index element={<HomePage />} /> 

              <Route path="about" element={<AboutPage/>}/>   
              <Route path="sharerequirements" element={<ShareReq/>}/>   
              <Route path="service1" element={<ReadMore1/>}/>   
              <Route path="service2" element={<ReadMore2/>}/>   
              <Route path="service3" element={<ReadMore3/>}/>   
              <Route path="service4" element={<ReadMore4/>}/>   
              <Route path="service5" element={<ReadMore5/>}/>   
              <Route path="service6" element={<ReadMore6/>}/>   
              <Route path="service7" element={<ReadMore7/>}/>   
              <Route path="service8" element={<ReadMore8/>}/>   
              <Route path="service9" element={<ReadMore9/>}/>   
              <Route path="service10" element={<ReadMore10/>}/>   
              <Route path="service11" element={<ReadMore11/>}/>   
              <Route path="service12" element={<ReadMore12/>}/>   
              <Route path="service13" element={<ReadMore13/>}/>   
              <Route path="service14" element={<ReadMore14/>}/>   
              <Route path="login" element={<LoginPage/>}/>   
      
           
      </Route>
    
    </Routes> 
  );
}

export default App;
