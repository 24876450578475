import React, {useEffect} from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore3 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='ServicePage'>

      <div className='proj_LargeHead'>Carpenter Services</div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
        Looking for a skilled carpenter to bring your woodworking projects to life? 
        Our team of experienced professionals offers a wide range of carpentry services, 
        from custom cabinetry and furniture building to home renovations and repairs. 
        With a focus on quality craftsmanship and attention to detail, 
        we strive to exceed your expectations and create beautiful, 
        functional pieces that will last for years to come.
        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
        Whether you need a new kitchen or bathroom installed, 
        a custom closet built, or simply some minor repairs, 
        our carpenters have the expertise to handle it all. 
        We use only the finest materials and techniques to 
        ensure that your projects are completed to the highest standards. 
        Contact us today to discuss your carpentry needs and receive a free estimate.
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>Cabinetry</li>
          <li className='proj_service'>Furniture building</li>
          <li className='proj_service'>Home renovations</li>
          <li className='proj_service'>Custom woodwork</li>
          <li className='proj_service'>Flooring installation</li>
          <li className='proj_service'>Trim and molding</li>
          <li className='proj_service'>Repair and restoration</li>
        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}


export default ReadMore3