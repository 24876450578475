import React, { useEffect } from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore7 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='ServicePage'>

      <div className='proj_LargeHead'>Experienced Driver</div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
          Need a reliable and experienced driver to get you where you need to go? Our professional drivers are committed to providing safe, comfortable, and efficient transportation. Whether you need a ride to the airport, a business meeting, or a special event, our drivers will ensure you arrive on time and in style.

        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
          Our drivers are courteous, knowledgeable, and familiar with the local area. They prioritize your safety and comfort, and they will always go the extra mile to ensure your satisfaction. We offer a variety of services, including airport transfers, hourly rates, and long-distance travel. Contact us today to book your ride and experience the best in transportation.

        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>Daily commuting</li>
          <li className='proj_service'>Airport transfers</li>
          <li className='proj_service'>Errand running</li>
          <li className='proj_service'>Event transportation</li>
          <li className='proj_service'>Long-distance travel</li>
          <li className='proj_service'>Child transportation</li>
          <li className='proj_service'>Elderly care transportation</li>
          <li className='proj_service'>Pet transportation</li>


        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore7