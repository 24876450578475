import React, {useEffect} from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore5 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    return (
        <div className='ServicePage'>
    
          <div className='proj_LargeHead'>Interior Work Services</div>
    
          <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
        Transform your living space with our exceptional interior work services. From complete renovations to minor updates, our skilled team of professionals can bring your design vision to life. We specialize in a wide range of interior projects, including painting, flooring, tiling, cabinetry, and more. With a commitment to quality craftsmanship and attention to detail, we strive to create beautiful and functional spaces that exceed your expectations.
        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
        Whether you're looking to modernize your home, enhance its value, or simply create a more comfortable and inviting environment, our interior work services are the perfect solution. We offer personalized consultations to understand your unique needs and preferences, and we work closely with you throughout the entire project to ensure your satisfaction. Contact us today to schedule a consultation and let us transform your space into something truly special.
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>Painting</li>
          <li className='proj_service'>Flooring</li>
          <li className='proj_service'>Tiling</li>
          <li className='proj_service'>Cabinetry</li>
          <li className='proj_service'>Wallpapering</li>
          <li className='proj_service'>Drywall</li>
          <li className='proj_service'>Ceilings</li>
          <li className='proj_service'>Trim and molding</li>
          <li className='proj_service'>Home renovations</li>
          <li className='proj_service'>Custom woodwork</li>
          <li className='proj_service'>Home repairs</li>
        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore5