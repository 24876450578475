import React, { useEffect } from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore7 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='ServicePage'>

      <div className='proj_LargeHead'>House Cleaner Services
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
          Let our professional house cleaners transform your living space into a spotless haven. With our meticulous attention to detail and commitment to quality, we provide comprehensive cleaning services that cater to your specific needs. From routine cleaning to deep cleaning, our team of experienced cleaners will leave your home sparkling clean and fresh.
        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
          Our services include dusting, mopping, vacuuming, cleaning bathrooms and kitchens, doing laundry, and ironing. We use eco-friendly cleaning products and follow strict safety protocols to ensure a healthy and safe environment for you and your family. Whether you need a one-time deep clean or regular cleaning services, trust us to deliver exceptional results.

        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>General cleaning</li>
          <li className='proj_service'>Kitchen cleaning</li>
          <li className='proj_service'>Bathroom cleaning</li>
          <li className='proj_service'>Laundry</li>
          <li className='proj_service'>Ironing</li>
          <li className='proj_service'>Bed making</li>
          <li className='proj_service'>Dishwashing</li>
          <li className='proj_service'>Window cleaning</li>
          <li className='proj_service'>Deep cleaning</li>
          <li className='proj_service'>Organization</li>
          <li className='proj_service'>Pet care</li>

        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore7