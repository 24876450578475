import React, { useState, useEffect } from 'react'
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import './HomeMain.css'
import landing from '../../../assets/banner image.png'
import homeMSME from '../../../assets/homeMSME.png'



const cities = [
    'Delhi', 'Mumbai', 'Kolkata', 'Bangalore', 'Chennai', 'Hyderabad', 'Pune', 'Ahmedabad', 'Surat', 'Lucknow', 'Jaipur', 'Kanpur', 'Mirzapur', 'Nagpur', 'Ghaziabad', 'Supaul', 'Vadodara', 'Rajkot', 'Vishakhapatnam', 'Indore', 'Thane', 'Bhopal', 'Pimpri-Chinchwad', 'Patna', 'Bilaspur', 'Ludhiana', 'agra', 'Madurai', 'Jamshedpur', 'Prayagraj', 'Nasik', 'Faridabad', 'Meerut', 'Jabalpur', 'Kalyan', 'Vasai-Virar', 'Najafgarh', 'Varanasi', 'Srinagar', 'Aurangabad', 'Dhanbad', 'Amritsar', 'Aligarh', 'Guwahati', 'Haora', 'Ranchi', 'Gwalior', 'Chandigarh', 'Haldwani', 'Vijayavada', 'Gaya', 'Rajgir'
];

const services = [
    'Administrative Support', 'Facility Service', 'Housekeeping Services', 'Customer Service', 'Blue Collar', 'White Collar', 'Reception Service', 'Security Service', 'IT Support', 'Catering Service', 'AC/Telephone Repair', 'Electrician/Plumber Service', 'Mailroom Service', 'Pest Control', 'Office Boy', 'Other'
]

const texts = [
    "Looking to Upgrade Your Vendor Needs.",
    "Connecting Businesses to Vendor Excellence.",
    "One Vendor, One Contract, One Payment Solution"
];

const HomeMain = () => {
    const navigate = useNavigate();

    const [searchInput, setSearchInput] = useState('');
    const [freqSearch, setfreqSearch] = useState('');
    const [searchType, setSearchType] = useState('service');
    const [service, setService] = useState(true);
    const [city, setCity] = useState(false);



    useEffect(() => {
        if (searchType === 'city') {
            setCity(true);
            setService(false);
        }
        else {
            setCity(false);
            setService(true);
        }
    }, [searchType])


    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const findSearchedInput = ()=>{
        
    }
   

    return (
        <>
            <div className='HomeMain'>

                <div className='landingContent'>
                    <div className='landingHeadingDiv'>

                        <div className='mainHead'>
                            <div className="animText" key={currentIndex}>
                                {texts[currentIndex]}
                            </div>
                        </div>

                        <div className='landingsubHead'>
                            Empowering companies and service providers together
                        </div>

                    </div>

                    <div className='landingContentImgDiv'>
                        <img src={homeMSME} className='landingContentImg' />
                    </div>
                </div>

                <div className='landingImgDiv'>
                    <img src={landing} className='landingImg' />
                </div>
            </div>

        </>
    )
}

export default HomeMain