import React, {useEffect} from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore4 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    return (
        <div className='ServicePage'>
    
          <div className='proj_LargeHead'>AC Installation & Repair Services</div>
    
          <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
        Stay cool and comfortable with our expert AC installation and repair 
        services. Our team of certified technicians is dedicated to providing 
        top-quality service and ensuring your air conditioning system operates 
        at peak efficiency. Whether you need a new unit installed, a repair for 
        your existing system, or routine maintenance, we have the expertise to 
        handle it all.
        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
        We offer a wide range of services, including AC installations, repairs, 
        maintenance, and tune-ups. Our technicians are equipped with the latest 
        tools and technology to diagnose and address any issues promptly. 
        We also offer emergency AC services for those urgent situations that can't wait. 
        Trust us to keep your home or business cool and comfortable throughout the year.
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>AC installation</li>
          <li className='proj_service'>AC repair</li>
          <li className='proj_service'>AC maintenance</li>
          <li className='proj_service'>AC tune-ups</li>
          <li className='proj_service'>Emergency AC services</li>
          <li className='proj_service'>Duct cleaning</li>
          <li className='proj_service'>Thermostat replacement</li>
          <li className='proj_service'>Energy efficiency upgrades</li>
        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore4