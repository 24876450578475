import React, { useEffect, useState } from 'react'
import logo from '../../assets/logo.png'
import './Nav.css'
import CallIcon from '@mui/icons-material/Call';
import { Link } from 'react-router-dom';
import axios from 'axios';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const Nav = () => {
  const navigate = useNavigate();

  const [callus, setcallus] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);

  const handleClick = () => {
    // navigate('/https://forms.gle/k4h965w1DFTFGrCcA', { replace: true });
    window.location.replace('https://forms.gle/k4h965w1DFTFGrCcA');
  };


  return (
    <div className='Nav'>
      <Link to="/">
        <div className='navLogoDiv'>
          <img src={logo} className='navLogo' />
        </div>
      </Link>
      <div className='navItems'>
        <div> <a href="/about">About Us</a></div>

        <div>
          <Link to='/sharerequirements'>
            Share Requirements
          </Link>

          {/* <button onClick={handleClick}>
           Share Requirements
          </button> */}
        </div>

      </div>
      <div className='navBtns'>
        <button className={callus ? 'navSignupBtn navbtnwidth' : 'navSignupBtn'}
          onClick={() => setcallus(!callus)}> <CallIcon />
          {callus ?
            <>+918810523732</>
            :
            <>
              Call Us
            </>}
        </button>




      </div>

      <div className='hamburgerIcon'
        onClick={() => setShowMobileNav(true)}>
        <MenuIcon />
      </div>

      {
        showMobileNav ?
          <div className='mobileNavContainer'>

            <div className='mobileNav'>

              <div className='closeMobileNavBtn' onClick={() => setShowMobileNav(false)}>X</div>

              <div className='mobilenavItems'>
                <div> <a href="about">About Us</a></div>

                <div>
                  <Link to={`sharerequirements`} >Share Requirements</Link>
                </div>

              </div>

              <div className='mobilenavBtns'>
                <button className={callus ? 'navSignupBtn navbtnwidth' : 'navSignupBtn'}
                  onClick={() => setcallus(!callus)}> <CallIcon />
                  {callus ?
                    <>+918810523732</>
                    :
                    <>
                      Call Us
                    </>}
                </button>





              </div>
            </div>

          </div>

          :
          <></>
      }
    </div>
  )
}

export default Nav
