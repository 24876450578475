import React, { useEffect } from 'react'
import './ReadMore.css'
import {Link} from 'react-router-dom'


const ReadMore7 = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='ServicePage'>

      <div className='proj_LargeHead'>Maid</div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Why choose us?
        </div>
        <div className='proj_text'>
          Let our reliable and trustworthy maids take care of your household chores, allowing you to focus on what matters most. Our professional cleaning staff is experienced in all aspects of home maintenance, from general cleaning and laundry to deep cleaning and organization. With a commitment to quality service and customer satisfaction, we strive to create a clean and comfortable living environment for you.

        </div>
      </div>

      <div className='proj_Section'>

        <div className='proj_text'>
          Our maids are trained to handle a variety of tasks, including dusting, mopping, vacuuming, cleaning bathrooms and kitchens, doing laundry, and ironing. We use eco-friendly cleaning products and follow strict safety protocols to ensure a healthy and safe home. Whether you need daily, weekly, or monthly cleaning services, our team is ready to provide you with the highest level of care.
        </div>
      </div>

      <div className='proj_Section'>
        <div className='proj_Head'>
          Services
        </div>
        <ul className='proj_Services'>
          <li className='proj_service'>General cleaning</li>
          <li className='proj_service'>Kitchen cleaning</li>
          <li className='proj_service'>Bathroom cleaning</li>
          <li className='proj_service'>Laundry</li>
          <li className='proj_service'>Ironing</li>
          <li className='proj_service'>Bed making</li>
          <li className='proj_service'>Dishwashing</li>
          <li className='proj_service'>Window cleaning</li>
          <li className='proj_service'>Deep cleaning</li>
          <li className='proj_service'>Organization</li>
          <li className='proj_service'>Pet care</li>
        </ul>
        <Link to='/sharerequirements'>
          <button className='serviceBtn'>
            Share Your requirements
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReadMore7